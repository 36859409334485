import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import {
  PaymentsRequestResponseType,
  fetchPaymentsRequest,
  fetchUserPaymentsRequest,
  updateUserPaymentRequest,
  destroyUserPaymentRequest,
  fetchSaftRequest,
  createPaymentToGoactiveRequest,
  completePaymentRequest
} from "requests/paymentsRequests";
import {
  fetchPaymentsSucceeded,
  fetchUserPaymentsSucceeded,
  setErrorMessage,
  updateUserPaymentSucceeded,
  deleteUserPaymentSucceeded,
  FetchPaymentsType,
  FetchSaftType,
  fetchSaftSucceeded,
  createPaymentToGoactiveSucceeded,
  completePaymentSucceeded,
  CompletePaymentType
} from "slices/paymentsSlice";
import { UpdatePayment, DestroyPayment } from "types/payments";

export function* fetchPaymentsSaga(
  action: PayloadAction<FetchPaymentsType>,
  request = fetchPaymentsRequest
) {
  const response: PaymentsRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchPaymentsSucceeded(response.payments));
  } else {
    setErrorMessage(response.errorMessage);
  }
}

export function* fetchSaftSaga(
  action: PayloadAction<FetchSaftType>,
  request = fetchSaftRequest
) {
  const response: PaymentsRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchSaftSucceeded(response.saft!));
  } else {
    setErrorMessage(response.errorMessage);
  }
}

export function* fetchUserPaymentsSaga(
  action: PayloadAction<string>,
  request = fetchUserPaymentsRequest
) {
  const response: PaymentsRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchUserPaymentsSucceeded(response.userPayments));
  } else {
    setErrorMessage(response.errorMessage);
  }
}

export function* updateUserPaymentSaga(
  action: PayloadAction<UpdatePayment>,
  request = updateUserPaymentRequest
) {
  const response: PaymentsRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updateUserPaymentSucceeded(response.userPayment));
  } else {
    setErrorMessage(response.errorMessage);
  }
}

export function* deleteUserPaymentSaga(
  action: PayloadAction<DestroyPayment>,
  request = destroyUserPaymentRequest
) {
  const response: PaymentsRequestResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteUserPaymentSucceeded(action.payload.paymentId));
  } else {
    setErrorMessage(response.errorMessage);
  }
}

export function* createPaymentToGoactiveSaga(
  action: any,
  request = createPaymentToGoactiveRequest
) {
  const response: PaymentsRequestResponseType = yield call(request);

  if (response.succeeded) {
    yield put(createPaymentToGoactiveSucceeded(response.paymentUrl));
  } else {
    setErrorMessage(response.errorMessage);
  }
}

export function* completePaymentSaga(
  action: PayloadAction<CompletePaymentType>,
  request = completePaymentRequest
) {
  const response: PaymentsRequestResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(completePaymentSucceeded(action.payload.id))
  } else {
    setErrorMessage(response.errorMessage);
  }
}
