import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchUsersSucceeded,
  setErrorMessage,
  createUserSucceeded,
  CreateUserType,
  updateUserSucceeded,
  deleteUserSucceeded,
  UpdateUserType,
  fetchUserSucceeded,
  fetchUserCountersSucceeded,
  reInviteUserSucceeded,
  fetchUsersWithMissingPaymentsSucceeded,
  FetchUserRevenueType,
  fetchRevenueSucceeded,
  reActivateUserSucceeded,
  DeleteUserType
} from "../../slices/userSlice";
import { put, call } from "redux-saga/effects";
import {
  fetchUsersRequest,
  fetchUserRequest,
  createUserRequest,
  updateUserRequest,
  deleteUserRequest,
  UserRequestResponseType,
  fetchUserCountersRequest,
  reInviteUserRequest,
  usersWithMissingPaymentsRequest,
  fetchUserRevenueCountersRequest,
  reActivateUserRequest,
} from "../../requests/userRequests";

export function* fetchUsersSaga(action: any, request = fetchUsersRequest) {
  const response: UserRequestResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchUsersSucceeded(response.users!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchUserSaga(
  action: PayloadAction<string>,
  request = fetchUserRequest
) {
  const response: UserRequestResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(fetchUserSucceeded(response.user!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchUserCountersSaga(
  action: PayloadAction<any>,
  request = fetchUserCountersRequest
) {
  const response: UserRequestResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchUserCountersSucceeded(response.userCounters!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchRevenueSaga(
  action: PayloadAction<FetchUserRevenueType>,
  request = fetchUserRevenueCountersRequest
) {
  const response: UserRequestResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(fetchRevenueSucceeded(response.userRevenue!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchUsersWithMissingPaymentsSaga(
  action: PayloadAction<any>,
  request = usersWithMissingPaymentsRequest
) {
  const response: UserRequestResponseType = yield call(request);

  if (response.succeeded) {
    yield put(
      fetchUsersWithMissingPaymentsSucceeded(response.usersWithMissingPayments!)
    );
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createUserSaga(
  action: PayloadAction<CreateUserType>,
  request = createUserRequest
) {
  const response: UserRequestResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(createUserSucceeded(response.user!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* reInviteUserSaga(
  action: PayloadAction<String>,
  request = reInviteUserRequest
) {
  const response: UserRequestResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(reInviteUserSucceeded());
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateUserSaga(
  action: PayloadAction<UpdateUserType>,
  request = updateUserRequest
) {
  const response: UserRequestResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(updateUserSucceeded(response.user!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* deleteUserSaga(
  action: PayloadAction<DeleteUserType>,
  request = deleteUserRequest
) {
  const response: UserRequestResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(deleteUserSucceeded(action.payload.userId));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* reActivateUserSaga(
  action: PayloadAction<String>,
  request = reActivateUserRequest
) {
  const response: UserRequestResponseType = yield call(request, action.payload);

  if (response.succeeded) {
    yield put(reActivateUserSucceeded());
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}
