import { takeLatest } from "redux-saga/effects";
import {
  fetchPromotingCounters,
} from "../../slices/promotingSlice";
import {
  fetchPromotingCountersSaga
} from "./promotingSaga";

function* promotingSaga() {
  yield takeLatest(fetchPromotingCounters, fetchPromotingCountersSaga);
}

export default promotingSaga;

