import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";

import "./Pricing.css";
import { useTranslation } from "react-i18next";
import { SignInModal } from "components/SignInModal/SignInModal";
import { GInput } from "components/genericComponents/GInput/GInput";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlans, selectPlans } from "slices/companyPlanSlice";
import i18n from "i18n";
import { GButton } from "components/genericComponents/GButton/GButton";
import { toast } from "react-toastify";

export const Pricing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const plans = useSelector(selectPlans);
  const [discountCode, setDiscountCode] = useState<string | undefined>(
    undefined
  );
  const [isMonthly, setIsMonthly] = useState<boolean>(true);
  const [showSignInModal, setSignInModal] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [maxNrOfPlans, setMaxNrOfPlans] = useState(0);
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [discount, setDiscount] = useState("");

  useEffect(() => {
    dispatch(fetchPlans({ code: undefined, locale: i18n.language }));
  }, [dispatch]);

  useEffect(() => {
    if (plans) {
      if (isMonthly) {
        setMaxNrOfPlans(Object.keys(plans.monthly.plans).length - 1);
        handleSetPrice(index);
        if (plans.monthly.discount_percentage !== 0) {
          setDiscount(`${plans.monthly.discount_percentage}%`);
        } else {
          setDiscount("");
        }
      } else {
        setMaxNrOfPlans(Object.keys(plans.yearly.plans).length - 1);
        handleSetPrice(index);
        setDiscount(`${plans.yearly.discount_percentage}%`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMonthly, plans, index]);

  const handleSetPrice = (priceIndex: number) => {
    if (plans) {
      if (isMonthly) {
        setPrice(plans!.monthly.plans[priceIndex]!.cost);
        setDescription(plans!.monthly.plans[priceIndex]!.description);
      } else {
        setPrice(plans!.yearly.plans[priceIndex]!.cost);
        setDescription(plans!.yearly.plans[priceIndex]!.description);
      }
    }
  };

  const handleCheckPrice = () => {
    if (discountCode !== undefined && discountCode !== "") {
      dispatch(fetchPlans({ code: discountCode, locale: i18n.language }));
    }
  };

  useEffect(() => {
    if (plans !== undefined && discountCode !== undefined && !plans.has_code) {
      toast.error(t("landingPage.pricing.codeDoesNotExist"), {
        toastId: "course-schedule-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (
      plans !== undefined &&
      (discountCode !== undefined || discountCode !== "") &&
      plans.has_code
    ) {
      toast.success(t("landingPage.pricing.codeAppliedSuccessfully"), {
        toastId: "course-schedule-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, plans]);

  return (
    <Container fluid id="pricing">
      <Row>
        <Col>
          <Container className="pricing-container">
            <Row>
              <Col className="pricing-title-col">
                <h1 className="pricing-title">
                  {t("landingPage.pricing.title")}
                </h1>
              </Col>
            </Row>
            <Row className="pricing-schedule-row">
              <Col className="pricing-schedule-col">
                <p
                  onClick={() => setIsMonthly(true)}
                  className={`pricing-schedule-text ${
                    isMonthly ? "active" : ""
                  }`}
                >
                  {t("landingPage.pricing.monthly")}
                </p>
                <p
                  onClick={() => setIsMonthly(false)}
                  className={`pricing-schedule-text ${
                    !isMonthly ? "active" : ""
                  }`}
                >
                  {t("landingPage.pricing.yearly")}
                </p>
              </Col>
            </Row>
            <Row className="pricing-plan-row">
              <Col className="pricing-plan-col">
                <p className="pricing-discount">{discount}</p>
                <Row className="pricing-prices-row">
                  <Col className="pricing-prices-col">
                    <h2 className="pricing-prices-title">
                      {t("landingPage.pricing.plans")}
                    </h2>
                    <h2 className="pricing-prices-price">{price}</h2>
                    {!isMonthly && index !== 0 ? (
                      <p className="pricing-prices-clients">
                        {t("landingPage.pricing.totalCost")}
                        {`${Number.parseFloat(
                          (Number.parseFloat(price.split("€")[0]) * 12).toFixed(
                            2
                          )
                        )}€`}
                      </p>
                    ) : undefined}
                    <RangeSlider
                      value={index}
                      onChange={(changeEvent) => {
                        setIndex(Number.parseInt(changeEvent.target.value));
                        handleSetPrice(
                          Number.parseInt(changeEvent.target.value)
                        );
                      }}
                      min={0}
                      max={maxNrOfPlans}
                      step={1}
                      tooltip="off"
                    />
                    <p className="pricing-prices-clients">{description}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="pricing-no-vat-col">
                <p>{t("landingPage.pricing.vat")}</p>
              </Col>
            </Row>
            <Row>
              <Col className="pricing-discount-code-col">
                <h4>{t("landingPage.pricing.discountCode")}</h4>
                <GInput
                  value={discountCode}
                  onChange={(e: any) => setDiscountCode(e.target.value)}
                  className="pricing-discount-code-input"
                />
                <GButton
                  label={t("landingPage.pricing.checkCode")}
                  onClick={handleCheckPrice}
                />
              </Col>
            </Row>
            <Row>
              <Col className="pricing-no-vat-col">
                <p>{t("landingPage.pricing.discountTerm")}</p>
              </Col>
            </Row>
            <Row>
              <Col className="pricing-try-now-btn">
                <Button
                  className="welcome-demo-btn"
                  onClick={() => setSignInModal(true)}
                >
                  {t("landingPage.welcomeComponent.joinWaitlistModal.tryNow")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      {showSignInModal ? (
        <SignInModal
          showSignInModal={showSignInModal}
          setShowSignInModal={setSignInModal}
          discountCodeStr={discountCode}
          isMonthly={isMonthly}
          nrOfMembers={index}
        />
      ) : undefined}
    </Container>
  );
};
