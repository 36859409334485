import { ReactNode } from "react";
import { Col, Container, ContainerProps, Row } from "react-bootstrap";

import "components/genericComponents/GSection/GSection.scss";

type GSectionProps = ContainerProps & {
  label?: string;
  renderButtons?: ReactNode[];
  isCentered?: boolean;
  isSpacedBetween?: boolean;
};

export const GSection = ({
  children,
  label,
  renderButtons,
  isCentered,
  isSpacedBetween,
  ...props
}: GSectionProps) => {
  return (
    <Container fluid id="gSection" {...props}>
      <Row>
        <Col
          className={`column${isCentered ? " centered" : ""}${
            isSpacedBetween ? " is-spaced-between" : ""
          }`}
        >
          {label && <h4>{label}</h4>}
          <div className="buttons-div">{renderButtons?.map((btn) => btn)}</div>
        </Col>
      </Row>
      {children}
    </Container>
  );
};
