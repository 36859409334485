import { axiosRequest, errorMessage } from "./request";
import { PromotingCounters } from "../slices/promotingSlice";

export type PromotingResponseType = {
  succeeded: boolean
  counters: PromotingCounters;
  errorMessage: string | undefined;
};

export const fetchPromotingCountersRequest =
  async (): Promise<PromotingResponseType> => {
    try {
      const response = await axiosRequest().get("promoting/counters");
      const counters: PromotingCounters = response.data;

      return {
        succeeded: true,
        counters: counters,
      } as PromotingResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as PromotingResponseType;
    }
  };

