import { takeLatest } from "redux-saga/effects";
import {
  fetchDiets,
  fetchDiet,
  createDiet,
  updateDiet,
  deleteDiet,
  duplicateDiet,
} from "../../slices/dietSlice";
import {
  fetchDietsSaga,
  fetchDietSaga,
  createDietSaga,
  updateDietSaga,
  deleteDietSaga,
  duplicateDietSaga,
} from "./dietSaga";

function* dietSaga() {
  yield takeLatest(fetchDiets, fetchDietsSaga);
  yield takeLatest(fetchDiet, fetchDietSaga);
  yield takeLatest(createDiet, createDietSaga);
  yield takeLatest(updateDiet, updateDietSaga);
  yield takeLatest(deleteDiet, deleteDietSaga);
  yield takeLatest(duplicateDiet, duplicateDietSaga);
}

export default dietSaga;

