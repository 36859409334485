import React, { SetStateAction, useEffect, useState } from "react";
import { TableComponent } from "../TableComponent/TableComponent";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import {
  fetchUsers,
  selectUsers,
  selectErrorMessage,
  selectConfirmationMessage,
  setErrorMessage,
  setConfirmationMessage,
  selectIsLoading,
  setCurrentUser,
  setCurrentClient,
  deleteUser,
} from "../../slices/userSlice";
import { selectCurrentUser } from "../../slices/currentUserSlice";
import { toast } from "react-toastify";
import { User } from "../../types/user";
import { SpinnerWrapper } from "../SpinnerWrapper/SpinnerWrapper";

import "./DashboardCustomersPage.css";
import { DashboardTitle } from "../DashboardTitle/DashboardTitle";
import { useNavigate } from "react-router-dom";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { Container, Form } from "react-bootstrap";
import { FilterDropdown } from "../FilterDropdown/FilterDropdown";
import { fetchPersonalTrainers, selectPersonalTrainers } from "../../slices/personalTrainerSlice";
import ReactSlider from "react-slider";
import { calculateAge, isMonthDayInRange } from "helpers/date";
import { GDatePicker } from "components/genericComponents/GDatePicker/GDatePicker";
import { DateRange } from "react-day-picker";
import { formatToFirstTwoAndLastName } from "helpers/utils";
import { GChip } from "components/genericComponents/GChip/GChip";
import { Tabs, TabsI } from "components/Tabs/Tabs";
import { CRMLastNotes } from "./CRMLastNotes/CRMLastNotes";
import { GModal } from "components/genericComponents/GModal/GModal";
import { GButton } from "components/genericComponents/GButton/GButton";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

type DashBoardCustomersPageProps = {
  navigateToWorkoutsPage?: () => void;
};

type SelectData = {
  id: string;
  name: string;
  image_small: string;
};

export const DashBoardCustomersPage = (props: DashBoardCustomersPageProps) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const clientsFiltersLocalStorage = localStorage.getItem("clientsFilters");
  const users = useSelector(selectUsers);
  const currentUser = useSelector(selectCurrentUser);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const isLoading = useSelector(selectIsLoading);
  const personalTrainers = useSelector(selectPersonalTrainers);
  const [usersAlreadyFetched, setUsersAlreadyFetched] = useState(false);
  const showUpgradePlanMessage = !currentUser?.company?.can_have_more_users;
  const defaultCustomers = users.filter((user: User) => user.id !== currentUser?.id);
  const [customers, setCustomers] = useState(defaultCustomers);
  const customersStates = ["invited", "completed", "discarded"];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined);
  const [selectedState, setSelectedState] = useState<{ id: string; title: string } | undefined>(
    clientsFiltersLocalStorage ? JSON.parse(clientsFiltersLocalStorage).selectedState : undefined
  );
  const [selectedPT, setSelectedPT] = useState<SelectData | undefined>(
    clientsFiltersLocalStorage ? JSON.parse(clientsFiltersLocalStorage).selectedPT : undefined
  );
  const [selectedAgeInterval, setSelectedAgeInterval] = useState<[number, number]>(
    clientsFiltersLocalStorage ? JSON.parse(clientsFiltersLocalStorage).selectedAgeInterval : [0, 99]
  );
  const [dateInterval, setDateInterval] = useState<DateRange | undefined>(
    clientsFiltersLocalStorage ? JSON.parse(clientsFiltersLocalStorage).dateInterval : undefined
  );
  const [clientNumber, setClientNumber] = useState<number | undefined>(
    clientsFiltersLocalStorage ? JSON.parse(clientsFiltersLocalStorage).clientNumber : undefined
  );
  const [searchInput, setSearchInput] = useState<string | undefined>(
    clientsFiltersLocalStorage ? JSON.parse(clientsFiltersLocalStorage).searchInput : undefined
  );
  const isGymView = localStorage.getItem("isGymView") === "true";
  const tabs = isGymView || (currentUser && !currentUser.company!.is_gym) ? ["Clientes", "CRM"] : [];
  const [tabsData, setTabsData] = useState<TabsI>({
    tabs: tabs,
    activeIndex: 0,
  });
  useCookies(["current_user"]);

  useEffect(() => {
    localStorage.setItem(
      "clientsFilters",
      JSON.stringify({
        selectedPT,
        selectedState,
        selectedAgeInterval,
        dateInterval,
        clientNumber,
        searchInput,
      })
    );
  }, [selectedPT, selectedState, selectedAgeInterval, dateInterval, clientNumber, searchInput]);

  const onTabClick = (activeIdx: number) => {
    setTabsData({
      ...tabsData,
      activeIndex: activeIdx,
    });
  };

  const portugueseUserState = (state: string) => {
    if (state === "completed") return "Completo";
    if (state === "invited") return "Convidado";
    if (state === "discarded") return "Inativo";
  };

  useEffect(() => {
    dispatch(fetchPersonalTrainers());
  }, [dispatch]);

  useEffect(() => {
    setCustomers(users.filter((user: User) => user.id !== currentUser?.id));
  }, [users, currentUser?.id]);

  const tableColumns = React.useMemo(() => {
    if (localStorage.getItem("isGymView") === "true") {
      return [
        {
          Header: "Nome",
          accessor: "image_small",
          Cell: (props: any) => {
            return (
              <div className="dashboard-customers-cell-image-div">
                <img
                  className="dashboard-workouts-cell-image"
                  src={props.cell.value ?? "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"}
                  alt={""}
                />
                <span>{formatToFirstTwoAndLastName(props.row.original.name)}</span>
              </div>
            );
          },
          width: 10,
        },
        {
          Header: "Número Cliente",
          accessor: "identifier",
          Cell: (props: any) => {
            return props.row.original.identifier !== null ? `${props.row.original.identifier}` : "";
          },
        },
        {
          Header: "Personal Trainer",
          accessor: "personal_trainer",
          Cell: (props: any) => {
            if (props.cell.value === null || props.cell.value === undefined) return "";

            return (
              <div className="dashboard-workouts-cell-image-div">
                <img
                  className="dashboard-workouts-cell-image"
                  src={props.cell.value.image_small ?? "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"}
                  alt={""}
                />
                <p className="dashboard-workouts-cell-p">{formatToFirstTwoAndLastName(props.cell.value?.name)}</p>
              </div>
            );
          },
        },
        {
          Header: "Email",
          accessor: "email",
        },
        {
          Header: "Pagamentos",
          accessor: "payment_in_delay",
          Cell: (props: any) => {
            if (props.row.original.status === "discarded") return;

            if (props.cell.value === 0) {
              return <BsFillCheckSquareFill className="dashboard-customers-payment-check" />;
            } else {
              return (
                <div className="dashboard-customers-payment-card">
                  <p>{props.cell.value} €</p>
                </div>
              );
            }
          },
        },
        {
          Header: "Estado",
          accessor: "status",
          Cell: (props: any) => {
            if (props.cell.value === "completed") return <GChip label="Completo" variant="infoFinished" />;
            if (props.cell.value === "invited") return <GChip label="Convidado" variant="infoPending" />;
            if (props.cell.value === "discarded") return <GChip label="Inativo" variant="infoExpired" />;
          },
        },
      ];
    } else {
      return [
        {
          accessor: "image_small",
          Cell: (props: any) => {
            return (
              <img
                className="dashboard-workouts-cell-image"
                src={props.cell.value ?? "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"}
                alt={""}
              />
            );
          },
          width: 10,
        },
        {
          Header: "Nome",
          accessor: "name",
          Cell: (props: any) => {
            return formatToFirstTwoAndLastName(props.row.original.name);
          },
        },
        {
          Header: "Email",
          accessor: "email",
        },
        {
          Header: "Pagamentos",
          accessor: "payment_in_delay",
          Cell: (props: any) => {
            if (props.row.original.status === "discarded") return;

            if (props.cell.value === 0) {
              return (
                <BsFillCheckSquareFill
                  style={{
                    height: 30,
                    width: 30,
                    color: "green",
                    marginLeft: 0,
                  }}
                />
              );
            } else {
              return (
                <div className="dashboard-customers-payment-card">
                  <p>{props.cell.value} €</p>
                </div>
              );
            }
          },
        },
        {
          Header: "Estado",
          accessor: "status",
          Cell: (props: any) => {
            if (props.cell.value === "completed") return <GChip label="Completo" variant="infoFinished" />;
            if (props.cell.value === "invited") return <GChip label="Convidado" variant="infoPending" />;
            if (props.cell.value === "discarded") return <GChip label="Inativo" variant="infoExpired" />;
          },
        },
      ];
    }
  }, []);

  useEffect(() => {
    if (!usersAlreadyFetched && users.length === 0) {
      dispatch(fetchUsers());
      setUsersAlreadyFetched(true);
    }
  }, [dispatch, usersAlreadyFetched, users]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "customers-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "customers-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(fetchUsers());
      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  useEffect(() => {
    // Only call changeCustomersList if clientNumber is undefined
    if (clientNumber === undefined) {
      // Create a synthetic event or use a default value to mimic the event structure
      const syntheticEvent = { target: { value: searchInput || "" } };
      changeCustomersList(syntheticEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientNumber]);

  const renderCustomerPage = (selectedUser: User) => {
    dispatch(setCurrentClient(selectedUser.id));
    let path = `${selectedUser.id}/personal-data`;
    navigate(path);
  };

  const filterCustomersByClientNumber = (evt: any) => {
    const inputValue = evt.target.value !== "" ? Number.parseInt(evt.target.value) : undefined;

    setClientNumber(inputValue);
    if (inputValue === undefined) {
      // Reset to default customers if clientNumber is cleared
      setCustomers(defaultCustomers);
    } else {
      // Apply clientNumber filter immediately
      const customersFiltered = defaultCustomers.filter((customer: User) => customer.identifier === inputValue);
      setCustomers(customersFiltered.length ? customersFiltered : []);
    }
  };

  const changeCustomersList = (evt: any) => {
    if (clientNumber !== undefined) return; // Skip if clientNumber is defined

    const inputValue = evt.target.value !== "" ? evt.target.value.toLowerCase() : "";
    setSearchInput(evt.target.value);

    const customersFiltered = defaultCustomers.filter(
      (customer: User) =>
        customer.name?.toLowerCase().includes(inputValue) || customer.email?.toLowerCase().includes(inputValue)
    );
    setCustomers(customersFiltered.length ? customersFiltered : []);
  };

  const customersToShowOnList = () => {
    // If clientNumber is defined, filter by clientNumber only
    if (clientNumber !== undefined) {
      return defaultCustomers.filter((customer: User) => customer.identifier === clientNumber);
    }

    // Apply other filters only if clientNumber is not defined
    return customers.filter((customer: User) => {
      const isPTSelected = selectedPT !== undefined ? customer.personal_trainer?.id === selectedPT?.id : true;
      const isStateSelected = selectedState !== undefined ? customer.status === selectedState.id : true;
      const customerAge = calculateAge(customer.birthday_date ? new Date(customer.birthday_date) : null);
      const isAgeInRange = customerAge >= selectedAgeInterval[0] && customerAge <= selectedAgeInterval[1];
      const isBirthdayInRange =
        dateInterval?.from !== undefined && dateInterval?.to !== undefined
          ? customer.birthday_date !== undefined &&
            isMonthDayInRange(new Date(customer.birthday_date!), dateInterval.from, dateInterval.to)
          : true;

      return isPTSelected && isStateSelected && isAgeInRange && isBirthdayInRange;
    });
  };

  const handleSetDateInterval = (dateInterval: DateRange) => {
    setDateInterval(dateInterval);
  };

  return (
    <Container fluid id="dashboard-customers">
      {isLoading ? (
        <div style={{ height: "100vh" }}>
          <SpinnerWrapper />
        </div>
      ) : (
        <>
          <DashboardTitle
            title=""
            showAddButton={!showUpgradePlanMessage}
            linkObj={{ isLink: true, link: "add-customer" }}
            showUpgradePlanMessage={showUpgradePlanMessage}
            countCards={[
              {
                title: "Clientes",
                count: users.filter((user: User) => user.status !== "discarded").length,
              },
            ]}
            infoLink="JHvFiXGGEVk"
          />
          {tabsData.tabs.length > 0 && (
            <Tabs tabs={tabsData.tabs} activeIndex={tabsData.activeIndex} onTabClick={onTabClick} />
          )}
          {(tabsData.activeIndex === 0 || tabs.length === 0) && (
            <>
              <div className="filter-container">
                <div className="filter-container-client-number-div">
                  <Form.Control
                    value={clientNumber}
                    onChange={filterCustomersByClientNumber}
                    placeholder="Número de cliente"
                    type="number"
                    className="search-input"
                  />
                  <div className="filter-container-filters-group">
                    <Form.Control
                      value={searchInput}
                      onChange={changeCustomersList}
                      placeholder="Pesquisar"
                      className="search-input"
                    />
                    {localStorage.getItem("isGymView") === "true" && (
                      <FilterDropdown
                        title={"Personal Trainer"}
                        values={personalTrainers.map((personal_trainer: User) => {
                          return {
                            id: personal_trainer.id!,
                            name: formatToFirstTwoAndLastName(personal_trainer.name),
                            image_small: personal_trainer.image_small,
                          };
                        })}
                        selectedValue={selectedPT}
                        onChangeValue={(personalTrainerId: any) => {
                          if (personalTrainerId === undefined) return setSelectedPT(undefined);

                          const newValue = personalTrainers.find(
                            (personalTrainer) => personalTrainer.id === personalTrainerId
                          );

                          setSelectedPT({
                            id: newValue!.id,
                            name: formatToFirstTwoAndLastName(newValue!.name),
                            image_small: newValue!.image_small,
                          });
                        }}
                      />
                    )}
                    <FilterDropdown
                      title={"Estado"}
                      values={customersStates.map((customerState: string) => {
                        return {
                          id: customerState,
                          title: portugueseUserState(customerState),
                        };
                      })}
                      selectedValue={selectedState}
                      onChangeValue={(selectedStateId: any) => {
                        if (selectedStateId === undefined) return setSelectedState(undefined);

                        const newValue = customersStates.find((customerState) => customerState === selectedStateId);

                        setSelectedState({
                          id: newValue!,
                          title: portugueseUserState(newValue!)!,
                        });
                      }}
                    />
                    <div>
                      <p className="customers-dashboard-slider-title">Intervalo de idades</p>
                      <ReactSlider
                        className="customers-dashboard-slider"
                        thumbClassName="customers-dashboard-slider-thumb"
                        trackClassName="customers-dashboard-slider-track"
                        defaultValue={[0, 99]}
                        min={0}
                        max={99}
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        renderTrack={(props, state) => <div {...props}></div>}
                        minDistance={1}
                        onChange={(e) => {
                          setSelectedAgeInterval(e as SetStateAction<[number, number]>);
                        }}
                      />
                    </div>
                    <div>
                      <p className="customers-dashboard-slider-title">Filtrar por aniversários</p>
                      <GDatePicker
                        mode="range"
                        setDateInterval={handleSetDateInterval}
                        currDateInterval={dateInterval}
                        className="date-picker"
                        clearable
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-component-upper-div">
                <TableComponent
                  name="customer"
                  columns={tableColumns}
                  data={customersToShowOnList()}
                  addAction={() => {}}
                  rowClickAction={(row: any) => {
                    renderCustomerPage(row.original);
                  }}
                  removeAction={(id: string) => {
                    setDeleteId(id);
                    if (users.find((user: User) => user.id === id)?.status === "discarded") setIsChecked(true);
                    setIsModalOpen(true);
                  }}
                  duplicateAction={(id: string) => {}}
                  seeChildrenText={
                    currentUser?.role === "admin" && localStorage.getItem("isGymView") === "true"
                      ? undefined
                      : "Planos de treino"
                  }
                  showRemoveButton
                  seeChildrenAction={(userId: string) => {
                    navigate("../workouts");
                    dispatch(setCurrentUser(userId));
                  }}
                  textWhenNoItems="Não tem nenhum utilizador"
                />
                <GModal
                  showModalState={isModalOpen}
                  setShowModalState={(val) => setIsModalOpen(val)}
                  onClose={() => {
                    setIsModalOpen(false);
                    setIsChecked(false);
                    setDeleteId(undefined);
                  }}
                  buttons={[
                    <GButton
                      variant="delete"
                      label={isChecked ? "Eliminar permanentemente" : "Meter como inativo"}
                      onClick={() => {
                        dispatch(deleteUser({ userId: deleteId!, permanently_delete: isChecked }));
                        setIsModalOpen(false);
                        setIsChecked(false);
                        setDeleteId(undefined);
                      }}
                    />,
                    <GButton variant="secondary" label="Cancelar" onClick={() => {
                      setIsModalOpen(false);
                      setIsChecked(false);
                      setDeleteId(undefined);
                    }} />
                  ]}
                >
                  <div className="customer-payments-modal-div">
                    <h3>Eliminar cliente</h3>
                    <div className="customer-payments-modal-div-checkbox" style={{ marginBottom: "20px" }}>
                      <div>
                        <p>Eliminar permanentemente?</p>
                        <p className="dashboard-customers-modal-div-checkbox-inner-p">Esta ação é irreversível.</p>
                      </div>
                      <GButton
                        variant="transparent"
                        className="invoice-check-button"
                        icon={isChecked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
                        iconSize="big"
                        onClick={() => setIsChecked(!isChecked)}
                      />
                    </div>
                  </div>
                </GModal>
              </div>
            </>
          )}
          {tabsData.activeIndex === 1 && <CRMLastNotes />}
        </>
      )}
    </Container>
  );
};
