import { useEffect, useState } from "react";

import { GInput } from "components/genericComponents/GInput/GInput";

import "components/NutritionPage/DietPage/DietPage.scss";
import { GButton } from "components/genericComponents/GButton/GButton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDiet,
  selectConfirmationMessage,
  selectDiet,
  selectErrorMessage,
  selectIsLoading,
  setConfirmationMessage,
  setErrorMessage,
  updateDiet,
} from "slices/dietSlice";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { DietMeal } from "../Diets/DietMeal/DietMeal";
import { CreateOrUpdateDietType, CreateOrUpdateMealType } from "slices/dietSlice";
import { SelectForm } from "components/SelectForm/SelectForm";
import { fetchUsers, selectUsers, selectIsLoading as selectUsersIsLoading } from "slices/userSlice";
import { DEFAULT_IMAGE } from "helpers/constants";
import { SpinnerWrapper } from "components/SpinnerWrapper/SpinnerWrapper";
import tinycolor from "tinycolor2";
import { Meal } from "types/diet";
import { Food } from "types/nutrition";

export const DietPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector(selectUsers);
  const currDiet = useSelector(selectDiet);
  const isDietLoading = useSelector(selectIsLoading);
  const isUsersLoading = useSelector(selectUsersIsLoading);
  const dietId = useParams().id;
  const [selectedUser, setSelectedUser] = useState<{ id: string; title: string; image: string } | undefined>(undefined);
  const [diet, setDiet] = useState<CreateOrUpdateDietType>({
    title: "",
    description: "",
    user_id: "",
    meals: [],
  });
  const [ingredients, setIngredients] = useState<Food[][]>([]);
  const [weights, setWeights] = useState<number[][]>([]);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  useEffect(() => {
    dispatch(fetchUsers());
    if (dietId) {
      dispatch(fetchDiet(dietId));
    }
  }, [dispatch, dietId]);

  const setIngs = (ings: Food[], index: number) => {
    const newIngredients = [...ingredients];
    newIngredients[index] = ings;
    setIngredients(newIngredients);
  };

  const setWeigs = (weigs: number[], index: number) => {
    const newWeights = [...weights];
    newWeights[index] = weigs;
    setWeights(newWeights);
  };

  const firstSetIngredient = (ingredient: Food, weight: number = 0, tempIngredients: Food[], tempWeights: number[]) => {
    tempIngredients.push({ ...ingredient, weight_in_g: weight });
    tempWeights.push(weight);
  };

  useEffect(() => {
    if (currDiet) {
      const tempIngredients = currDiet.meals.map(() => []);
      const tempWeights = currDiet.meals.map(() => []);

      currDiet.meals.forEach((meal: Meal, index: number) => {
        meal.meal_items.forEach((item: any, idx: number) => {
          const ingredient = meal.meal_items[idx].item as Food;
          firstSetIngredient(ingredient, item.weight_in_g, tempIngredients[index], tempWeights[index]);
        });
      });
      setIngredients(tempIngredients);
      setWeights(tempWeights);
      setSelectedUser({
        id: currDiet.user.id,
        title: currDiet.user.name ?? "",
        image: currDiet.user.image_small ?? DEFAULT_IMAGE,
      });

      const { user, type, totals, ...newDiet } = currDiet;
      setDiet({
        ...newDiet,
        user_id: currDiet.user.id,
        meals: currDiet.meals.map((meal) => {
          const { totals, type, ...newMeal } = meal;
          return {
            ...newMeal,
            meal_items: newMeal.meal_items.map((item) => {
              return {
                id: item.id,
                item_type: "Food",
                item_id: item.item.id,
                weight_in_g: item.weight_in_g,
              };
            }),
          };
        }),
      });
    }
  }, [currDiet]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "add-diet-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "add-diet-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
      navigate("/dashboard/nutrition/diets");
    }
  }, [dispatch, confirmationMessage, navigate]);

  const submitDiet = () => {
    dispatch(updateDiet(diet));
  };

  const setMeal = (meal: CreateOrUpdateMealType, index: number) => {
    const newMeals = [...diet.meals];
    newMeals[index] = meal;
    setDiet({ ...diet, meals: newMeals });
  };

  const deleteMeal = (index: number) => {
    const newMeals = [...diet.meals];
    newMeals.splice(index, 1);
    setDiet({ ...diet, meals: newMeals });
  };

  return (
    <>
      {isDietLoading || isUsersLoading ? (
        <div style={{ padding: "8px" }}>
          <SpinnerWrapper />
        </div>
      ) : (
        <div className="add-recipe-container">
          <div className="add-recipe-header">
            <h2>{diet.title ? diet.title : "Nova Dieta"}</h2>
          </div>
          <div className="info-div">
            <div className="info-div-inputs">
              <div>
                <p className="name">Nome da dieta</p>
                <GInput value={diet.title} onChange={(e: any) => setDiet({ ...diet, title: e.target.value })} />
              </div>
              <div>
                <p className="name">Cliente</p>
                <SelectForm
                  label="Cliente"
                  value={selectedUser}
                  hasImage
                  onChange={(e: any) => {
                    const user = users.find((user) => user.id === e.value)!;
                    setSelectedUser({
                      id: user.id,
                      title: user.name ?? "",
                      image: user.image_small ?? DEFAULT_IMAGE,
                    });
                    setDiet({ ...diet, user_id: user?.id });
                  }}
                  options={users.map((user) => ({
                    id: user.id,
                    title: user.name ?? "",
                    image: user.image_small,
                  }))}
                />
              </div>
              <div>
                <p className="name">Descrição</p>
                <GInput
                  value={diet.description}
                  onChange={(e: any) => setDiet({ ...diet, description: e.target.value })}
                  as="textarea"
                />
              </div>
              <div className="totals-div">
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#FF9800").brighten()} 0%, #FF9800 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {ingredients
                      ? ingredients.reduce(
                          (acc, curr, index) =>
                            acc + curr.reduce((acc, curr, idx) => acc + curr.calories * (weights[index][idx] / 100), 0),
                          0
                        ).toFixed(0)
                      : 0}
                    kcal
                  </p>
                  <p className="totals-div-info-container-name">Calorias</p>
                </div>
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#4CAF50").brighten()} 0%, #4CAF50 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {ingredients
                      ? ingredients.reduce(
                          (acc, curr, index) =>
                            acc + curr.reduce((acc, curr, idx) => acc + curr.protein * (weights[index][idx] / 100), 0),
                          0
                        ).toFixed(0)
                      : 0}
                    g
                  </p>
                  <p className="totals-div-info-container-name">Proteína</p>
                </div>
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#9C27B0").brighten()} 0%, #9C27B0 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {ingredients
                      ? ingredients.reduce(
                          (acc, curr, index) =>
                            acc + curr.reduce((acc, curr, idx) => acc + curr.carbs * (weights[index][idx] / 100), 0),
                          0
                        ).toFixed(0)
                      : 0}
                    g
                  </p>
                  <p className="totals-div-info-container-name">Hidratos</p>
                </div>
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#03A9F4").brighten()} 0%, #03A9F4 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {ingredients
                      ? ingredients.reduce(
                          (acc, curr, index) =>
                            acc + curr.reduce((acc, curr, idx) => acc + curr.lipids * (weights[index][idx] / 100), 0),
                          0
                        ).toFixed(0)
                      : 0}
                    g
                  </p>
                  <p className="totals-div-info-container-name">Lípidos</p>
                </div>
              </div>
            </div>
          </div>
          {diet.meals.map((meal, index) => (
            <DietMeal
              key={index}
              meal={meal}
              setMeal={setMeal}
              index={index}
              deleteMeal={deleteMeal}
              isEditing={true}
              diet={currDiet}
              ings={ingredients[index]}
              weigs={weights[index]}
              setIngs={setIngs}
              setWeigs={setWeigs}
            />
          ))}
          <div className="add-meal-button-div">
            <div className="submit-button-div">
              <GButton
                label="Adicionar Refeição"
                onClick={() =>
                  setDiet({
                    ...diet,
                    meals: [...diet.meals, { title: `Refeição ${diet.meals.length}`, description: "", meal_items: [] }],
                  })
                }
              />
            </div>
          </div>
          {diet.meals.length > 0 && diet.title && diet.user_id && (
            <div className="diet-submit-button-div">
              <div className="submit-button-div">
                <GButton label="Submeter" variant="primary" onClick={submitDiet} />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
