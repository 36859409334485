// SUCCESS MESSAGES
export const courseScheduleSuccessfullyCreated = "Marcação criada com sucesso";
export const courseScheduleSuccessfullyUpdated = "Marcação editada com sucesso";
export const courseScheduleSuccessfullyDeleted =
  "Marcação eliminada com sucesso";

export const courseSuccessfullyCreated = "Aula criada com sucesso";
export const courseSuccessfullyUpdated = "Aula editada com sucesso";
export const courseSuccessfullyDeleted = "Aula eliminada com sucesso";

export const gymRoomSuccessfullyUpdated = "Divisões editadas com sucesso";

export const userSuccessfullyCreated = "Utilizador criado com sucesso";
export const userSuccessfullyUpdated = "Utilizador editado com sucesso";
export const userSuccessfullyDeleted = "Utilizador eliminado com sucesso";

export const personalTrainerSuccessfullyCreated =
  "Personal trainer criado com sucesso";
export const personalTrainerSuccessfullyUpdated =
  "Personal trainer editado com sucesso";
export const personalTrainerSuccessfullyDeleted =
  "Personal trainer eliminado com sucesso";

export const userEvaluationSuccessfullyCreated = "Avaliação criada com sucesso";
export const userEvaluationSuccessfullyUpdated =
  "Avaliação editada com sucesso";
export const userEvaluationSuccessfullyDeleted =
  "Avaliação eliminada com sucesso";

export const workoutSuccessfullyCreated = "Plano de treino criado com sucesso";
export const workoutSuccessfullyUpdated = "Plano de treino editado com sucesso";
export const workoutSuccessfullyDeleted =
  "Plano de treino eliminado com sucesso";

export const productSuccessfullyCreated = "Produto criado com sucesso";
export const productSuccessfullyUpdated = "Produto editado com sucesso";
export const productSuccessfullyDeleted = "Produto eliminado com sucesso";

export const productTransactionSuccessfullyCreated =
  "Transação criado com sucesso";
export const productTransactionSuccessfullyUpdated =
  "Transação editado com sucesso";
export const productTransactionSuccessfullyDeleted =
  "Transação eliminado com sucesso";

export const workoutHistorySuccessfullyCreated = "Treino criado com sucesso";

export const exerciseSuccessfullyCreated = "Exercício criado com sucesso";
export const exerciseSuccessfullyUpdated = "Exercício editado com sucesso";
export const exerciseSuccessfullyDeleted = "Exercício eliminado com sucesso";

export const dietSuccessfullyCreated = "Dieta criada com sucesso";
export const dietSuccessfullyUpdated = "Dieta editada com sucesso";
export const dietSuccessfullyDeleted = "Dieta eliminada com sucesso";
export const dietSuccessfullyDuplicated = "Dieta duplicada com sucesso";

export const exerciseCategorySuccessfullyCreated =
  "Categoria criada com sucesso";
export const exerciseCategorySuccessfullyUpdated =
  "Categoria editada com sucesso";
export const exerciseCategorySuccessfullyDeleted =
  "Categoria eliminada com sucesso";

export const crmNoteSuccessfullyCreated = "Nota criada com sucesso";

// INSURANCE
export const insuranceSuccessfullyUpdated = "Seguro atualizado com sucesso";

// ERROR MESSAGES
export const nameMessage = "Por favor preencha o nome";
export const surnameMessage = "Por favor preencha o apelido";
export const emailMessage = "Por favor preencha o email";
export const emailValidMessage = "Por favor use um email válido";
// This one is to be used on fetch request, when it's not the user's fault
export const fetchRequestFailed =
  "Pedido falhou. Pedimos desculpa pelo incómodo. Tente outra vez por favor!";
// This one is to be used on create/update requests, when probably is the user's fault
export const createOrUpdateRequestFailed = "Pedido falhou";
