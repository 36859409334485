import { User, UserCounter, UserWithMissingPayments } from "../types/user";
import { axiosRequest, errorMessage } from "./request";
import {
  CreateUserType,
  UpdateUserType,
  FetchUserRevenueType,
  DeleteUserType
} from "../slices/userSlice";

export type UserRequestResponseType = {
  succeeded: boolean;
  users?: Array<User> | undefined;
  user?: User | undefined;
  userCounters?: Array<UserCounter> | undefined;
  usersWithMissingPayments?: Array<UserWithMissingPayments> | undefined;
  errorMessage?: string | undefined;
  userRevenue: number;
};

export const fetchUsersRequest = async (): Promise<UserRequestResponseType> => {
  try {
    const response = await axiosRequest().get("users");
    const users: Array<User> = response.data.map((user: User) => user);

    return {
      succeeded: true,
      users: users,
    } as UserRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as UserRequestResponseType;
  }
};

export const fetchUserRequest = async (
  userId: string
): Promise<UserRequestResponseType> => {
  try {
    const response = await axiosRequest().get(`users/${userId}`);
    const user: User = response.data;

    return {
      succeeded: true,
      user: user,
    } as UserRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as UserRequestResponseType;
  }
};

export const fetchUserCountersRequest =
  async (): Promise<UserRequestResponseType> => {
    try {
      const response = await axiosRequest().get(`counters/users`);
      const counters: Array<UserCounter> = response.data;

      return {
        succeeded: true,
        userCounters: counters,
      } as UserRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as UserRequestResponseType;
    }
  };

export const fetchUserRevenueCountersRequest = async (
  params: FetchUserRevenueType
): Promise<UserRequestResponseType> => {
  try {
    const response = await axiosRequest().get(
      `counters/user_revenue?start_at=${params.start_at}&end_at=${params.end_at}`
    );
    const revenue: number = response.data.revenue;

    return {
      succeeded: true,
      userRevenue: revenue,
    } as UserRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as UserRequestResponseType;
  }
};

export const createUserRequest = async (
  params: CreateUserType
): Promise<UserRequestResponseType> => {
  try {
    const response = await axiosRequest().post("users", {
      user: params,
    });
    const user: User = response.data;

    return { succeeded: true, user: user } as UserRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as UserRequestResponseType;
  }
};

export const reInviteUserRequest = async (
  userId: String
): Promise<UserRequestResponseType> => {
  try {
    await axiosRequest().post(`users/${userId}/re_invite_user`);
    return { succeeded: true } as UserRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as UserRequestResponseType;
  }
};

export const updateUserRequest = async (
  params: UpdateUserType
): Promise<UserRequestResponseType> => {
  try {
    const response = await axiosRequest().put(`users/${params.id}`, {
      user: params,
    });
    const user: User = response.data;

    return { succeeded: true, user: user } as UserRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as UserRequestResponseType;
  }
};

export const usersWithMissingPaymentsRequest =
  async (): Promise<UserRequestResponseType> => {
    try {
      const response = await axiosRequest().get(`users_with_missing_payments`);
      const data: Array<UserWithMissingPayments> = response.data;

      return {
        succeeded: true,
        usersWithMissingPayments: data,
      } as UserRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as UserRequestResponseType;
    }
  };

export const deleteUserRequest = async (
  params: DeleteUserType
): Promise<UserRequestResponseType> => {
  try {
    await axiosRequest().delete(`users/${params.userId}?permanently_delete=${params.permanently_delete}`);

    return { succeeded: true } as UserRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as UserRequestResponseType;
  }
};

export const reActivateUserRequest = async (
  userId: String
): Promise<UserRequestResponseType> => {
  try {
    await axiosRequest().post(`users/${userId}/reactivate`);
    return { succeeded: true } as UserRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as UserRequestResponseType;
  }
};
