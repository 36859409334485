import { PayloadAction } from "@reduxjs/toolkit";
import {
  DietResponseType,
  fetchDietsRequest,
  fetchDietRequest,
  createDietRequest,
  updateDietRequest,
  deleteDietRequest,
  duplicateDietRequest,
} from "../../requests/dietRequests";
import { call, put } from "redux-saga/effects";
import {
  CreateOrUpdateDietType,
  setErrorMessage,
  fetchDietsSucceeded,
  fetchDietSucceeded,
  createDietSucceeded,
  updateDietSucceeded,
  deleteDietSucceeded,
  duplicateDietSucceeded,
} from "slices/dietSlice";

export function* fetchDietsSaga(
  action: any,
  request = fetchDietsRequest
) {
  const response: DietResponseType = yield call(
    request
  );

  if (response.succeeded) {
    yield put(fetchDietsSucceeded(response.diets!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* fetchDietSaga(
  action: PayloadAction<string>,
  request = fetchDietRequest
) {
  const response: DietResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(fetchDietSucceeded(response.diet!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* createDietSaga(
  action: PayloadAction<CreateOrUpdateDietType>,
  request = createDietRequest
) {
  const response: DietResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(createDietSucceeded(response.diet!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* updateDietSaga(
  action: PayloadAction<CreateOrUpdateDietType>,
  request = updateDietRequest
) {
  const response: DietResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(updateDietSucceeded(response.diet!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* deleteDietSaga(
  action: PayloadAction<string>,
  request = deleteDietRequest
) {
  const response: DietResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(deleteDietSucceeded(action.payload))
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}

export function* duplicateDietSaga(
  action: PayloadAction<string>,
  request = duplicateDietRequest
) {
  const response: DietResponseType = yield call(
    request,
    action.payload
  );

  if (response.succeeded) {
    yield put(duplicateDietSucceeded(response.diet!));
  } else {
    yield put(setErrorMessage(response.errorMessage!));
  }
}