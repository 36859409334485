import {
  fetchPromotingCountersSucceeded,
} from "../../slices/promotingSlice";
import { put, call } from "redux-saga/effects";
import {
  fetchPromotingCountersRequest,
  PromotingResponseType
} from "../../requests/promotingRequests";

export function* fetchPromotingCountersSaga(
  action: any,
  request = fetchPromotingCountersRequest
) {
  const response: PromotingResponseType = yield call(request);

  if (response.succeeded) {
    yield put(fetchPromotingCountersSucceeded(response.counters!));
  } else {
  }
}

