import { DashboardTitle } from "components/DashboardTitle/DashboardTitle";
import { GSection } from "components/genericComponents/GSection/GSection";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "slices/currentUserSlice";

import "./PromoterPage.scss";
import { GCard } from "components/genericComponents/GCard/GCard";
import { useEffect } from "react";
import { fetchPromotingCounters, selectPromotingCounters } from "slices/promotingSlice";

export const PromoterPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const counters = useSelector(selectPromotingCounters)

  useEffect(() => {
    dispatch(fetchPromotingCounters())
  }, [dispatch])

  return (
    <div className="promoter-page">
      <DashboardTitle title="Promotor" doNotShowBackButton />
      <GSection label="Código">
        <div className="promoter-page-code-outter-container">
          <div className="promoter-page-code-inner-container">
            <p>{user?.company?.company_plan_code}</p>
          </div>
        </div>
      </GSection>
      <div className="promoter-page-cards-container">
        <GCard isSquared value={counters?.total_earnings} description="Total acumulado" />
        <GCard isSquared value={counters?.total_utilizations} description="Total utilizações" />
        <GCard isSquared value={counters?.total_utilizations_waiting_for_payment} description="Utilizações sem pagamento (ainda dentro dos 3 meses)" /> 
        <GCard isSquared value={counters?.total_utilizations_with_payment_in_delay} description="Utilizações sem pagamento (fora dos 3 meses)" /> 
      </div>
    </div>
  );
};
