import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type PromotingCounters = {
  total_earnings: number
  total_utilizations: number
  total_utilizations_waiting_for_payment: number
  total_utilizations_with_payment_in_delay: number
}

type PromotingState = {
  counters: PromotingCounters | undefined
};

const initialState: PromotingState = {
  counters: undefined
};

const promotingSlice = createSlice({
  name: "promoting",
  initialState: initialState,
  reducers: {
    fetchPromotingCounters(state: PromotingState) {
    },
    fetchPromotingCountersSucceeded(
      state: PromotingState,
      action: PayloadAction<PromotingCounters>
    ) {
      state.counters = action.payload
    },
  }
});

export const {
  fetchPromotingCounters,
  fetchPromotingCountersSucceeded,
} = promotingSlice.actions;

export const selectPromotingCounters = (state: RootState) =>
  state.promotingSlice.counters

export default promotingSlice.reducer;

